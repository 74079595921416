import React, { FC, useState, useCallback, useEffect} from 'react'
import { Route, Routes} from 'react-router-dom'
import {Helmet} from "react-helmet"
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import { Main } from '../../pages/Main/Main'
import { Footer } from '../Footer/Footer'
import { WebsitePresentation } from '../../pages/WebsitePresentation/WebsitePresentation'
import { LandingPage } from '../../pages/LandingPage/LandingPage'
import { PersonalWebsite } from '../../pages/PersonalWebsite/PersonalWebsite'
import { Questions } from '../../pages/Questions/Questions'
import { CorporateWebsite } from '../../pages/CorporateWebsite/CorporateWebsite'
import { TechnicalSupport } from '../../pages/TechnicalSupport/TechnicalSupport'
import { MyServices } from '../../pages/MyServices/MyServices'
import { Contacts } from '../../pages/Contacts/Contacts'
import { Works } from '../../pages/Works/Works'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { OnlineStore } from '../../pages/OnlineStore/OnlineStore'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import { ModalForm } from '../ModalForm/ModalForm'
import { Modal } from '../Modal/Modal'
import { UpButton } from '../UpButton/UpButton'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'

export const App: FC = () =>{

  const [isMenuMobile, setIsMenuMobile] = useState<boolean>(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  return (
    <div className={app.page}>
    <AppHeader
        isOpen={handleOpenMenuMobile}
      />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/voprosy'  element={
          <>
          <Helmet>
                <title>Ответы на часто задаваемые вопросы.</title>
                <meta name="description" content="Ответы на часто задаваемые вопросы."></meta>
                <meta property="og:title" content="Ответы на часто задаваемые вопросы."/>
                <meta property="og:description" content="Ответы на часто задаваемые вопросы."/>
                </Helmet>
          <Questions
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/sayt-vizitka'  element={
          <WebsitePresentation
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/landing-page'  element={
          <>
          <Helmet>
          <title>Создание продающих лендингов Мытищи, Королев, Балашиха</title>
          <meta name="description" content="Создание продающих лендингов под ключ.  Настройки рекламы и техничесой поддержки лендинга."/>
          <meta property="og:title" content="Создание продающих лендингов Мытищи, Королев, Балашиха"/>
          <meta property="og:description" content="Создание продающих лендингов под ключ.  Настройки рекламы и техничесой поддержки лендинга."/>
        </Helmet>
          <LandingPage
            isOpen={handleFormOpen}
          />
          </>
        }/>
        <Route  path='/personalnyy-sayt'  element={
          <>
          <Helmet>
                <title>Создание персонального сайта.</title>
                <meta name="description" content="Создание персонального сайта."/>
                <meta property="og:title" content="Создание персонального сайта."/>
                <meta property="og:description" content="Создание персонального сайта."/>
            </Helmet>
          <PersonalWebsite
            isOpen={handleFormOpen}
          />
          </>
          
        }/>
        <Route  path='/korporativnyy-sayt'  element={
          <CorporateWebsite
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/internet-magazin'  element={
          <OnlineStore
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/portfolio'  element={
          <>
          <Helmet>
                <title>Разработка сайтов Тихонов Дмитрий примеры работ</title>
                <meta name="description" content="Разработка сайтов Тихонов Дмитрий примеры работ"/>
                <link rel="canonical" href="https://tihonov-studio.ru/portfolio" />
                <meta property="og:url" content="https://tihonov-studio.ru/portfolio"/>
                <meta property="og:title" content="Разработка сайтов Тихонов Дмитрий примеры работ"/>
                <meta property="og:description" content="Разработка сайтов Тихонов Дмитрий примеры работ"/>
            </Helmet>
          <Works
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/uslugi'  element={
          <MyServices
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/kontakty'  element={
          <>
          <Helmet>
            <title>Контакты</title>
            <meta name="description" content="Контакты"/>
            <meta property="og:title" content="Контакты"/>
            <meta property="og:description" content="Контакты"/>
          </Helmet>
          <Contacts
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
          </>
        }/>
        <Route  path='/politika-konfidencialnosti'  element={
          <>
          <Helmet>
                <title>Политика конфиденциальности.</title>
                <meta name="description" content="Политика конфиденциальности."/>
                <meta property="og:title" content="Политика конфиденциальности."/>
                <meta property="og:description" content="Политика конфиденциальности."/>
          </Helmet>
          <PrivacyPolicy/>
          </>
        }/>
        <Route  path='/podderzhka-sayta'  element={
          <>
          <Helmet>
            <title>Техническая поддержка сайта Тихонов Дмитрий.</title>
            <meta name="description" content="Техническая поддержка сайта Тихонов Дмитрий."/>
          </Helmet>
          <TechnicalSupport
            isOpen={handleFormOpen}
          />
          </>
        }/>
        <Route path='*' element={
          <>
          <Helmet>
            <title>Ошибка 404 страницы не существует</title>
            <meta name="description" content="Такой страницы не существует"/>
            <meta property="og:title" content="Ошибка 404 страницы не существует"/>
            <meta property="og:description" content="Такой страницы не существует"/>
          </Helmet>
          <PageNotFound/>
          </>
        }/>
      </Routes>
      <Footer/>
      <MenuMobile
        onClose={handleCloseMenuMobile}
        isMenuMobile={isMenuMobile}
      />
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && <Modal
        onClose={handleClose}
      ></Modal>}
      <UpButton/>
      <ButtonCommunication
        isOpen={handleFormOpen}
      />
    </div>
  )
}