import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import {Helmet} from "react-helmet"
import styles from './WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_23.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'
import i from '../../image/Chestnost/i.png'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const WebsitePresentation:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main} 
            id='prices'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <Helmet>
                <title></title>
                <meta name="description" content="Создание сайта визитки под ключ."></meta>
                <meta property="og:title" content="Cоздание сайта визитки."/>
                <meta property="og:description" content="Создание сайта визитки под ключ."/>
            </Helmet>
            <BreadCrumbs
                text={'Сайт-визитка'}
            />
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Создание <span>сайта&#160;визитки</span></motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>25 000₽</span> от <span>5</span> дней</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>Дать своему бизнесу активный старт практически невозможно, не представив его в Online. Оптимальный способ для этого - создать сайт визитку. Сайт визитка - это своеобразная "начальная ступень", с которой удобно начитать продвижение информации о вашем деле. Он представляет собой небольшой, достаточно просто устроенный интернет-ресурс, носящий информационный характер.</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                    <div className={styles.podskazka}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_one}>
                                <p className={styles.podskazka_text}>Никаких конструкторов - только ручная валидная верстка</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_one}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text}>
                                <p className={styles.podskazka_text}>Авторский продающий WOW-дизайн</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_two}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_two}>
                                <p className={styles.podskazka_text}>Смысловая маркетинговая упаковка вашей компании и продукта в одностраничный продающий сайт</p>
                            </div>
                        </span>
				    </div>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Что такое <span>сайт&#8205;-&#8205;визитка</span></h2>
                <p className={styles.text}>Сайт-визитка — это сайт с информацией о вашей компании: услуги, цены, адрес, контакты и другие важные сведения. Чаще всего сайты-визитки напоминают небольшую презентацию с ключевыми тезисами, поэтому они полностью оправдывают свое название. Одно из преимуществ такой "электронной визитки" заключается в ее интерактивности (клиент может ознакомиться с вашим портфолио, отзывами других клиентов, заказать обратный звонок и оставить свои данные для консультации и пр.).</p>
                <p className={styles.text}>Сайты-визитки просты в разработке и дизайне, ключевая особенность такого сайта — минимализм и сдержанность в контенте, функционале и визуальных решениях. Главная цель — дать пользователю ознакомится с вашей компанией, ее услугами или товарами и иметь под рукой контактные данные. Потенциальные клиенты потратят минимум своего времени для ознакомления с ресурсом.</p>
                <p className={styles.text}>В последующем, сайт-визитка может перерасти в хороший корпоративный портал или интернет-магазин по продаже ваших  товаров и услуг. </p>
            </motion.div>
            <Advantages/>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <motion.h3 className={styles.title} variants={textAnimation}>Из чего состоит <span>сайт&#8205;-&#8205;визитка</span></motion.h3>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Информация о компании</h4>
                        <p className={styles.item_text}>В идеале описать историю, этапы развития, достижения, партнеров. При этом важно структурировать информацию, чтобы из-за монотонности подачи материала сплошным текстом- «простыней» читатели не пропустили полезные и значимые факты, способные повлиять на сотрудничество.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Информация о продуктах</h4>
                        <p className={styles.item_text}>Расскажите о том, что Вы делаете. Подготовьте качественный текст для описания своих товаров или услуг. Если есть возможность, соберите отзывы и покажите их на сайте в этом же разделе.   </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контакты</h4>
                        <p className={styles.item_text}>Предоставьте Вашим потенциальным клиентом исчерпывающую информацию о Ваших контактных данных: укажите адрес, номер телефона, электронную почту, ссылки на социальные сети, адрес офиса(желательно со схемой проезда на карте), график работы, реквизиты.</p>
                    </motion.li>
                </ul>
            </motion.div>
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}